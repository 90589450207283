<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <b-table
              head-variant="dark"
              striped
              hover
              :items="vet_pag_confirmar"
              responsive
              id="doc-itens"
              :fields="fields_itens_confirmar"
            >
              <template #cell(check)="row">
                <!-- <span>{{row.item.vecimento}}</span> -->
                <b-form-checkbox value="3" v-model="row.item.check">
                </b-form-checkbox>
              </template>
              <template #cell(opcoes)="row">
                <!-- <span>{{row.item.vecimento}}</span> -->
                <!-- <b-form-checkbox value="3" v-model="row.item.opcao">
                </b-form-checkbox> -->
                <button class="btn btn-secondary" @click="ola(row.item)" >Opção</button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      name: "Pagamentos",
      vet_pag_confirmar: [],
      fields_itens_confirmar: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Número",
          key: "id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "obervacoes",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "data",
          key: "data",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Conta",
          key: "conta",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "especie",
          key: "especie",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "PC",
          key: "tipo_id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "saldo",
          key: "saldo",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          label: "Ações",
          key: "opcoes",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Financeiro",
          href: "/",
          active: true,
        },
        {
          text: "Pagamento a confirmar",
          href: "/financeiro/confirmapagamentos",
          active: true,
        },
      ],
      titleBody: "confirmar pagamento",
    };
  },
  mounted() {
    this.get_pre_pedidos()
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  methods: {
    async get_pre_pedidos() {
      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_pag_confirmar = response.data;
          console.log(this.vet_pag_confirmar);
        } else {
          this.vet_pag_confirmar = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
  },
};
</script>
<style>
</style>